var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"car-brand-card mx-auto",attrs:{"elevation":hover ? 12 : 2,"max-width":"344","outlined":"","hover":""},on:{"click":function($event){return _vm.$emit('edit-location', _vm.id)}}},[_c('v-list-item',{staticClass:"mt-3",attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.locationName)+" ")]),_c('v-list-item-subtitle',{staticClass:"last-modified d-flex mt-1"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"x-small":""}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.icons.mdiCalendarBlankOutline))])]}}],null,true)},[_c('span',[_vm._v("Last Modified")])]),_c('div',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.updatedOn))])],1)],1)],1),_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"status-icon",attrs:{"x-small":"","color":_vm.isActive ? 'success' : 'error'}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.icons.mdiCircle))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.isActive ? 'Active' : 'Inactive'))])]),_c('div',{},[(_vm.isPopular)?_c('v-chip',{staticClass:"ma-2 v-chip-light-bg primary--text type-icon",attrs:{"color":"primary","small":""}},[_vm._v(" Popular ")]):_vm._e(),_c('v-icon',{staticClass:"ml-2 edit-icon",attrs:{"small":""}},[_vm._v(_vm._s(_vm.icons.mdiPencil))])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }