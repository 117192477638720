import useCommon from '@/composables/useCommon'
import useFirestoreCollectionNames from '@/composables/useFirestoreCollectionNames'
import { ref, watch } from '@vue/composition-api'
import {
  addDoc,
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  where,
  writeBatch,
} from 'firebase/firestore'
import { chunk, filter, isNil, orderBy as sortOrder } from 'lodash'
import { db } from '/src/firebase/config'

export default function useLocationList() {
  const { stringContainsAny } = useCommon()
  const { locationCollectionName, agentsCollectionName, selfDriveCarCollectionName, chauffeurDriveCarCollectionName } =
    useFirestoreCollectionNames()

  const blankLocationData = {
    active: true,
    description: '',
    maincity: true,
  }

  const locationData = ref(JSON.parse(JSON.stringify(blankLocationData)))

  const locations = ref([])
  const filteredLocations = ref([])
  const paginatedLocations = ref([])
  const error = ref(null)
  const page = ref(1)
  const itemsPerPage = ref(16)

  const searchQuery = ref('')
  const statusFilter = ref(true)
  const typeFilter = ref(null)
  const sortBy = ref(null)
  const loading = ref(false)
  let unsubscribe = null
  const options = ref({
    sortBy: ['updatedon'],
    sortDesc: [true],
  })

  const queryOptions = () => {
    const qOptions = []

    const orderByKey = options.value.sortBy[0] ? options.value.sortBy[0] : 'updatedon'
    const order = options.value.sortDesc[0] ? 'desc' : 'asc'
    qOptions.push(orderBy(orderByKey, order))

    return qOptions
  }

  // fetch data
  const fetchLocations = async () => {
    try {
      if (unsubscribe) {
        unsubscribe()
      }
      loading.value = true
      locations.value = []
      filteredLocations.value = []

      const q = query(collection(db, locationCollectionName.value), ...queryOptions())
      unsubscribe = onSnapshot(q, querySnapshot => {
        locations.value = []
        querySnapshot.forEach(doc => {
          locations.value.push({
            id: doc.id,
            ...doc.data(),
          })
        })
        filteredLocations.value = locations.value
        filterLocations()
      })
    } catch (e) {
      console.log(e)
    } finally {
      loading.value = false
    }
  }

  const checkIfLocationExist = async () => {
    const q = query(
      collection(db, locationCollectionName.value),
      where('description', '==', locationData.value.description),
    )
    const querySnapshot = await getDocs(q)
    return !!querySnapshot.size
  }

  const addLocation = async () => {
    try {
      error.value = null
      if (!locationData.value.description || !locationData.value.description.length) {
        throw new Error('Location is required!')
      }
      if (await checkIfLocationExist()) {
        throw new Error('Location already exist!')
      }
      const location = {
        ...locationData.value,
        createdon: serverTimestamp(),
        updatedon: serverTimestamp(),
      }
      await addDoc(collection(db, locationCollectionName.value), location)
    } catch (e) {
      error.value = e.message
      console.log(e)
    }
  }

  const editLocation = async id => {
    try {
      error.value = null
      if (!locationData.value.description || !locationData.value.description.length) {
        throw new Error('Location is required!')
      }
      const prevLocation = filter(locations.value, location => location.id == id)
      const location = {
        ...locationData.value,
        updatedon: serverTimestamp(),
      }
      if (prevLocation.length && prevLocation[0].description != location.description) {
        if (await checkIfLocationExist()) {
          throw new Error('Location already exist!')
        }
      }
      await batchUpdate(id, prevLocation[0], location)
      // const docReference = doc(db, locationCollectionName.value, id)
      // await updateDoc(docReference, location)
    } catch (e) {
      error.value = e.message
      console.log(e)
    }
  }

  const batchUpdate = async (locationId, prevLocation, newLocation) => {
    const { description, active } = newLocation
    const locationDocRef = doc(db, locationCollectionName.value, locationId)

    const agentCollection = agentsCollectionName.value
    const queryAgent = query(collection(db, agentCollection), where('location_id', '==', prevLocation.description))

    const selfDriveCollection = selfDriveCarCollectionName.value
    const querySelfDrive = query(collection(db, selfDriveCollection), where('location', '==', prevLocation.description))

    const chauffeurDriveCollection = chauffeurDriveCarCollectionName.value
    const queryChauffeurDrive = query(
      collection(db, chauffeurDriveCollection),
      where('location', '==', prevLocation.description),
    )
    const batch = writeBatch(db)

    // 1. Update brand
    batch.update(locationDocRef, newLocation)

    // 2. Update Agent
    const updateObjAgent = {
      location_id: description,
      locactive: active,
    }
    const agentItems = await getDocs(queryAgent)
    agentItems.forEach(document => {
      const docRef = doc(db, agentCollection, document.id)
      batch.update(docRef, updateObjAgent)
    })

    // 3. Update Self Drive
    const updateObjSelfDrive = {
      location: description,
      locactive: active,
    }
    const selfDriveItems = await getDocs(querySelfDrive)
    selfDriveItems.forEach(document => {
      const docRef = doc(db, selfDriveCollection, document.id)
      batch.update(docRef, updateObjSelfDrive)
    })

    // 4. Update Chauffeur Drive
    const updateObjChauffeurDrive = {
      location: description,
      locactive: active,
    }
    const chauffeurDriveItems = await getDocs(queryChauffeurDrive)
    chauffeurDriveItems.forEach(document => {
      const docRef = doc(db, chauffeurDriveCollection, document.id)
      batch.update(docRef, updateObjChauffeurDrive)
    })

    await batch.commit()
  }

  const filterLocations = () => {
    let filteredBrands = locations.value
    if (searchQuery.value && searchQuery.value.length) {
      filteredBrands = filter(filteredBrands, ({ description }) => {
        return stringContainsAny(description, searchQuery.value)
      })
    }

    if (!isNil(statusFilter.value)) {
      filteredBrands = filter(filteredBrands, ({ active }) => {
        return statusFilter.value == active
      })
    }

    if (!isNil(typeFilter.value)) {
      filteredBrands = filter(filteredBrands, ({ maincity }) => {
        return typeFilter.value == maincity
      })
    }

    if (!isNil(sortBy.value)) {
      filteredBrands = sortOrder(filteredBrands, [sortBy.value.by], [sortBy.value.order])
    }

    filteredLocations.value = filteredBrands
  }

  watch([searchQuery, statusFilter, typeFilter, sortBy], () => {
    filterLocations()
  })

  watch([filteredLocations, itemsPerPage], () => {
    const chunkSize = itemsPerPage.value == 'All' ? filteredLocations.value.length : itemsPerPage.value
    paginatedLocations.value = chunk(filteredLocations.value, chunkSize)
    page.value = 1
  })

  const unsubscribeLocations = () => {
    unsubscribe()
  }

  fetchLocations()

  return {
    page,
    itemsPerPage,
    locations,
    filteredLocations,
    paginatedLocations,
    searchQuery,
    statusFilter,
    typeFilter,
    sortBy,
    loading,
    locationData,
    blankLocationData,
    error,

    unsubscribeLocations,
    addLocation,
    editLocation,
  }
}
