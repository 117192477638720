<template>
  <v-navigation-drawer
    :value="isAddNewUserSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="val => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <v-card height="100%" class="add-location-card">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">{{ id ? 'Update' : 'Add' }} Location</span>
        <v-spacer></v-spacer>
        <v-btn icon small @click="resetcarBrandData">
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form ref="form" v-model="valid" @submit.prevent>
          <div class="location-input mb-6">
            <input v-model="locationData.description" type="text" placeholder="Location *" ref="locationRef" />
          </div>

          <v-switch
            v-model="locationData.maincity"
            inset
            :color="locationData.maincity ? 'primary' : 'error'"
            label="Popular"
          ></v-switch>

          <v-switch
            v-model="locationData.active"
            inset
            :color="locationData.active ? 'success' : 'error'"
            :label="`${locationData.active ? 'Active' : 'Inactive'}`"
          ></v-switch>

          <v-alert dense text color="error" v-if="error">
            {{ error }}
          </v-alert>

          <v-btn color="primary" class="me-3" @click.prevent="onSubmit">
            {{ id ? 'Update' : 'Add' }}
          </v-btn>
          <v-btn color="secondary" outlined type="reset" @click="resetcarBrandData"> Cancel </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mdiClose, mdiCircle } from '@mdi/js'
import { find, includes } from 'lodash'
import { onMounted, ref, watch } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import useLocation from './useLocation'
import useAppConfig from '@/@core/@app-config/useAppConfig'

export default {
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    id: {
      type: String | null,
      required: true,
    },
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    typeOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { country } = useAppConfig()
    const fileRules = [v => !!v || 'File is required', v => (v && v.size > 0) || 'File is required']
    const valid = ref(false)
    const form = ref(null)
    const locationRef = ref(null)

    const { addLocation, editLocation, locationData, blankLocationData, error, locations } = useLocation()

    const validate = () => {
      form.value.validate()
    }

    const resetForm = async () => {
      await form.value.reset()
      locationData.value = JSON.parse(JSON.stringify(blankLocationData))
    }

    const resetcarBrandData = () => {
      resetForm()
      error.value = null
      emit('reset-id')
      emit('update:is-add-new-user-sidebar-active', false)
    }

    const onSubmit = async () => {
      if (valid.value) {
        if (props.id) {
          await editLocation(props.id)
        } else {
          await addLocation()
        }
        if (!error.value) {
          emit('update:is-add-new-user-sidebar-active', false)
          resetcarBrandData()
        }
      } else {
        validate()
      }
    }

    watch(
      () => props.id,
      () => {
        if (props.id) {
          const location = find(locations.value, ['id', props.id])
          locationData.value.active = location.active
          locationData.value.description = location.description
          locationData.value.maincity = location.maincity
        }
      },
      { immediate: true },
    )

    watch(
      () => props.isAddNewUserSidebarActive,
      () => {
        if (!props.isAddNewUserSidebarActive) {
          resetcarBrandData()
        }
      },
    )

    onMounted(() => {
      let countryCode = 'in'
      if (country.value == 'UK') {
        countryCode = 'gbr'
      } else if (country.value == 'UAE') {
        countryCode = 'are'
      }
      const options = {
        componentRestrictions: { country: countryCode },
      }
      const autocomplete = new google.maps.places.Autocomplete(locationRef.value, options)

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace()
        const city = find(place.address_components, ({ types }) => {
          return includes(types, 'locality')
        })
        locationData.value.description = ''
        locationData.value.description = city.long_name
      })
    })

    return {
      resetcarBrandData,
      form,
      onSubmit,
      locationData,
      valid,
      validate,
      error,
      fileRules,
      locationRef,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
        mdiCircle,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
.add-location-card {
  .location-input {
    border-style: solid;
    border-color: grey;
    border-width: 1px;
    border-radius: 5px;
    font-weight: 400;
    input {
      color: white;
      width: 100%;
      border-radius: 5px;
      line-height: 2.5;
      padding-left: 10px;
      font-size: 16px;
    }
  }
}
</style>
